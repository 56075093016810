/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import SEO from "../../components/SEO";
import SignUpLayout from "../../components/SignUpLayout";
import { AToB112, LetsTalk112 } from "../../components/svg-assets/SignUpIcons";
import CoordPart from "../../components/sign-up/CoordPart";
import {
  EMPTY_REGION,
  defaultLeadData,
  CheckboxesField,
  ErrorRenderer,
  RegionField,
  SliderField,
  TextareaField,
  useField,
  validateRegion,
  validateRequired,
  Field,
} from "../../components/sign-up/common";
import ThankYouPart from "../../components/sign-up/ThankYouPart";
import homepage from "../../lib/homepage";

const ShipperKindPart = ({ defaults, onContinue }) => {
  const { t } = useTranslation();

  const [kind, setKind] = useState(defaults.kind);
  const [tosAccepted, setTosAccepted] = useState(defaults.tosAccepted ?? false);
  const [newsletter, setNewsletter] = useState(defaults.newsletter);

  return (
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.shipper.tag" /> (1/3)
      </div>
      <h2>
        <Trans i18nKey="sign-up.shipper.first.title" />
      </h2>
      <div className="su-two-choices">
        <input
          type="radio"
          id="kind-ship-now"
          name="kind"
          value="ShipNow"
          checked={kind === "ShipNow"}
          onChange={e => setKind(e.currentTarget.value)}
        />
        <label className="su-choice" htmlFor="kind-ship-now">
          <AToB112 />
          <p className="su-strong">
            <Trans i18nKey="sign-up.shipper.first.choices.ship-now.title" />
          </p>
          <Trans parent="p" i18nKey="sign-up.shipper.first.choices.ship-now.description" />
        </label>

        <input
          type="radio"
          id="kind-expert"
          name="kind"
          value="Expert"
          checked={kind === "Expert"}
          onChange={e => setKind(e.currentTarget.value)}
        />
        <label className="su-choice" htmlFor="kind-expert">
          <LetsTalk112 />
          <p className="su-strong">
            <Trans i18nKey="sign-up.shipper.first.choices.expert.title" />
          </p>
          <Trans parent="p" i18nKey="sign-up.shipper.first.choices.expert.description" />
        </label>
      </div>
      <label className="su-checkbox-label">
        <input
          type="checkbox"
          checked={tosAccepted}
          onChange={e => setTosAccepted(e.currentTarget.checked)}
        />
        <Trans
          i18nKey="sign-up.i-accept-the-tos"
          components={{
            touLink: (
              <a href={t("sign-up.tos-links.terms-of-use")} target="_blank" rel="noreferrer" />
            ),
            cgvLink: (
              <a href={t("sign-up.tos-links.terms-of-sale")} target="_blank" rel="noreferrer" />
            ),
            privacyLink: (
              <a href={t("sign-up.tos-links.privacy-policy")} target="_blank" rel="noreferrer" />
            ),
          }}
        />
      </label>
      <label className="su-checkbox-label">
        <input
          type="checkbox"
          checked={newsletter}
          onChange={e => setNewsletter(e.currentTarget.checked)}
        />
        <Trans
          i18nKey="sign-up.i-want-to-subscribe-to-the-newsletter"
          components={{
            privacyLink: (
              <a href={t("sign-up.tos-links.privacy-policy")} target="_blank" rel="noreferrer" />
            ),
          }}
        />
      </label>
      <p className="su-next-wrapper">
        <button
          className="su-next"
          disabled={!kind || !tosAccepted}
          onClick={() => onContinue(kind, tosAccepted, newsletter)}>
          <Trans i18nKey="sign-up.continue" />
        </button>
      </p>
    </div>
  );
};

const FrequencyPart = ({ defaults, onContinue, globalError }) => {
  const { t } = useTranslation();

  const frequency = useField(defaults.frequency, () => null);

  return (
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.shipper.tag" /> (2/4)
      </div>
      <h2>
        <Trans i18nKey="sign-up.shipper.frequency.title" />
      </h2>

      <SliderField
        field={frequency}
        max={3}
        stepLabels={[
          t("sign-up.shipper.frequency.choices.daily"),
          t("sign-up.shipper.frequency.choices.weekly"),
          t("sign-up.shipper.frequency.choices.monthly"),
          t("sign-up.shipper.frequency.choices.less-than-monthly"),
        ]}
      />

      <ErrorRenderer errorMessage={globalError} />

      <p className="su-next-wrapper">
        <button className="su-next" onClick={() => onContinue({ frequency })}>
          <Trans i18nKey="sign-up.continue" />
        </button>
      </p>
    </div>
  );
};

const OrganizationPart = ({ defaults, onContinue, saving, globalError }) => {
  const { t } = useTranslation();

  const fromArea = useField(defaults.fromArea, validateRegion);
  const toArea = useField(defaults.toArea, validateRegion);
  const volume = useField(defaults.volume, () => null);
  const goods = useField(defaults.goods, validateRequired(t("sign-up.errors.goods-is-required")));
  const shipping = useField(
    defaults.shipping,
    validateRequired(t("sign-up.errors.shipping-are-required"))
  );
  const referrals = useField(
    defaults.referrals,
    validateRequired(t("sign-up.errors.referrals-are-required"))
  );
  const comments = useField(defaults.comments, () => null);

  const allFields = [fromArea, toArea, volume, goods, shipping, referrals, comments];

  const forceValidation = () => {
    allFields.forEach(field => field.setError(field.validator()));
  };

  return (
    <div className="su-main fl-blue-context">
      <div className="fl-tag fl-naked-tag">
        <Trans i18nKey="sign-up.shipper.tag" /> (3/4)
      </div>
      <h2>
        <Trans i18nKey="sign-up.shipper.org.title" />
      </h2>

      <RegionField
        id="from-area"
        label={t("sign-up.shipper.org.from-area.label")}
        field={fromArea}
      />

      <RegionField id="to-area" label={t("sign-up.shipper.org.to-area.label")} field={toArea} />

      <SliderField
        field={volume}
        label={t("sign-up.shipper.org.volume.label")}
        max={3}
        stepLabels={[
          t("sign-up.shipper.org.volume.choices.less-than-fifty-a-month"),
          t("sign-up.shipper.org.volume.choices.fifty-to-a-hundred-a-month"),
          t("sign-up.shipper.org.volume.choices.a-hundred-to-five-hundred-a-month"),
          t("sign-up.shipper.org.volume.choices.more-than-five-hundred-a-month"),
        ]}
      />

      <Field field={goods} label={t("sign-up.shipper.org.goods.label")} />

      <CheckboxesField
        field={shipping}
        label={t("sign-up.shipper.org.shipping.label")}
        choices={{
          Groupage: t("sign-up.shipper.org.shipping.choices.Groupage"),
          LTL: t("sign-up.shipper.org.shipping.choices.LTL"),
          FTL: t("sign-up.shipper.org.shipping.choices.FTL"),
          Specific: t("sign-up.shipper.org.shipping.choices.Specific"),
        }}
      />

      <CheckboxesField
        field={referrals}
        label={t("sign-up.referrals.label")}
        choices={{
          Customer: t("sign-up.referrals.choices.Customer"),
          Team: t("sign-up.referrals.choices.Team"),
          Investor: t("sign-up.referrals.choices.Investor"),
          Press: t("sign-up.referrals.choices.Press"),
          Other: t("sign-up.referrals.choices.Other"),
        }}
      />

      <TextareaField label={t("sign-up.comments.label")} field={comments} />

      <ErrorRenderer errorMessage={globalError} />

      <p className="su-next-wrapper">
        <button
          className={`su-next ${saving ? "su-next--saving" : ""}`}
          disabled={allFields.some(field => Boolean(field.validator()))}
          onFocus={() => forceValidation()}
          onMouseOver={() => forceValidation()}
          onClick={() => (!saving ? onContinue(...allFields.map(field => field.value)) : () => {})}>
          <Trans i18nKey="sign-up.send" />
        </button>
      </p>
    </div>
  );
};

const SignUpShipperPage = () => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  const [leadId, setLeadId] = useState(null);

  const [kind, setKind] = useState(null);
  const [tosAccepted, setTosAccepted] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [frequency, setFrequency] = useState(0);
  const [organization, setOrganization] = useState(null);
  const [siret, setSiret] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [fromArea, setFromArea] = useState(EMPTY_REGION);
  const [toArea, setToArea] = useState(EMPTY_REGION);
  const [volume, setVolume] = useState(0);
  const [goods, setGoods] = useState(null);
  const [shipping, setShipping] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [comments, setComments] = useState(null);

  const [globalError, setGlobalError] = useState(null);

  const [savingStep, setSavingStep] = useState(null);

  const backStatus = () => {
    if (progress === 0) {
      return {
        backTitle: t("sign-up.shipper.not-a-shipper"),
        backTarget: "/sign-up/",
      };
    } else if (progress === 4) {
      return {
        backTitle: t("sign-up.back-to-website"),
        backTarget: "/",
      };
    } else {
      return {
        backTitle: t("sign-up.previous-step"),
        backTarget: undefined,
        onBack: () => setProgress(progress - 1),
      };
    }
  };

  const { backTitle, backTarget, onBack } = backStatus();

  const [alreadySaving, setAlreadySaving] = useState(false);
  useEffect(() => {
    if (!savingStep && alreadySaving) {
      setAlreadySaving(false);
    }
    // Do not send data twice.
    if (!savingStep || alreadySaving) return;

    setAlreadySaving(true);

    let fields = {
      leadId,
      category: kind,
      accept_conditions: tosAccepted,
      acceptNewsletter: newsletter,
      frequency,
      companyName: organization,
      companyNumber: siret,
      firstName,
      lastName,
      email,
      phoneNumber: phone,
    };

    if (savingStep === 3) {
      fields = {
        ...fields,
        from: fromArea,
        to: toArea,
        volume,
        goods,
        shipping,
        referrals,
        message: comments,
      };
    }

    const data = homepage.prepareLeadData({ ...defaultLeadData(), ...fields }, Object.keys(fields));

    homepage.sendLead(
      "shipper",
      data,
      successData => {
        const next = () => {
          if (successData.id) {
            setLeadId(successData.id);
          }
          setProgress(savingStep + 1);
          setGlobalError(null);
          setSavingStep(null);
        };

        if (savingStep === 3) {
          // Let's validate our lead, now it's complete!
          homepage.validateLead("shipper", leadId, next, () => {
            setSavingStep(null);
            setGlobalError(t("sign-up.errors.generic-api-error"));
          });
        } else {
          next();
        }
      },
      error => {
        console.log({ error });
        setSavingStep(null);

        if (error?.response?.field) {
          const { field: fieldName, errorType } = error.response;

          // This "fake" t() is there to ensure all i18n keys are effectively used with
          // the same pattern, so you could lookup them using `t(…)` in your editor.
          // It doesn't return a string, but an object that will be read by
          // ErrorRenderer which will interpolate links (from the i18n) safely.
          const t = i18nKey => ({ i18nKey });

          const errorMessage =
            errorType === "duplicate"
              ? t("sign-up.errors.we-already-know-you-login-or-contact-us")
              : t("sign-up.errors.generic-api-error");

          setGlobalError({ fieldName, ...errorMessage });
        } else {
          setGlobalError(t("sign-up.errors.generic-api-error"));
        }
      }
    );
  }, [
    savingStep,
    alreadySaving,
    leadId,
    kind,
    tosAccepted,
    newsletter,
    frequency,
    organization,
    siret,
    firstName,
    lastName,
    email,
    phone,
    fromArea,
    toArea,
    volume,
    goods,
    shipping,
    referrals,
    comments,
    t,
  ]);

  return (
    <SignUpLayout {...{ backTitle, backTarget, onBack }}>
      <SEO title={t("sign-up.shipper.html-title")} />
      {progress === 0 ? (
        <ShipperKindPart
          defaults={{ kind, tosAccepted, newsletter }}
          onContinue={(kind, tosAccepted, newsletter) => {
            setKind(kind);
            setTosAccepted(tosAccepted);
            setNewsletter(newsletter);
            setProgress(1);
          }}
        />
      ) : undefined}
      {progress === 1 ? (
        <FrequencyPart
          defaults={{ frequency }}
          saving={savingStep !== null}
          globalError={globalError}
          onContinue={frequency => {
            setFrequency(frequency);
            setProgress(2);
          }}
        />
      ) : undefined}
      {progress === 2 ? (
        <CoordPart
          tag={`${t("sign-up.shipper.tag")} (3/4)`}
          defaults={{ organization, siret, firstName, lastName, email, phone }}
          saving={savingStep !== null}
          globalError={globalError}
          onContinue={(organization, siret, firstName, lastName, email, phone) => {
            setOrganization(organization);
            setSiret(siret);
            setFirstName(firstName);
            setLastName(lastName);
            setEmail(email);
            setPhone(phone);
            setGlobalError(null);
            setSavingStep(2);
          }}
        />
      ) : undefined}
      {progress === 3 ? (
        <OrganizationPart
          defaults={{ fromArea, toArea, volume, goods, shipping, referrals, comments }}
          saving={savingStep !== null}
          globalError={globalError}
          onContinue={(fromArea, toArea, volume, goods, shipping, referrals, comments) => {
            setFromArea(fromArea);
            setToArea(toArea);
            setVolume(volume);
            setGoods(goods);
            setShipping(shipping);
            setReferrals(referrals);
            setComments(comments);
            setGlobalError(null);
            setSavingStep(3);
          }}
        />
      ) : undefined}
      {progress === 4 ? (
        <ThankYouPart>
          <p className="su-thank-you-message">
            {kind === "ShipNow" ? (
              <Trans i18nKey="sign-up.thank-you.shipper.ship-now" />
            ) : (
              <Trans i18nKey="sign-up.thank-you.shipper.expert" />
            )}
          </p>
        </ThankYouPart>
      ) : undefined}
    </SignUpLayout>
  );
};

export default SignUpShipperPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
